import { Provider } from "@radix-ui/react-tooltip";
import { useLoaderData } from "@remix-run/react";
import { createContext, useContext, useEffect, useRef, type ReactNode } from "react";
import { toast, Toaster } from "react-hot-toast";

import type GlobalFlashSession from "../flash-session.server/GlobalFlashSession.js";

// Set up Global Context
interface GlobalContextType {
  prefetch: "intent" | "render" | "none";
  texture?: boolean;
  timezone: string;
}

const defaultGlobalContext: GlobalContextType = {
  prefetch: "intent",
  timezone: "America/New_York",
};

const GlobalContext = createContext<GlobalContextType>(defaultGlobalContext);

interface Props {
  children?: ReactNode;

  // Optional Global Context
  options?: Partial<GlobalContextType>;
}

export const useGlobalContext = () => useContext(GlobalContext);

const UIProvider = ({ children, options }: Props) => {
  const loaderData = useLoaderData<GlobalFlashSession>();

  const lastToastId = useRef<string | null>(null);

  const { error_toast, success_toast, flash_id } = loaderData;

  useEffect(() => {
    if (flash_id !== lastToastId.current) {
      if (success_toast) {
        toast.success(success_toast, {
          id: "toast-success",
        });
      } else if (error_toast) {
        toast.error(error_toast, {
          id: "toast-error",
        });
      }

      if (flash_id) {
        lastToastId.current = flash_id;
      }
    }
  }, [flash_id]);

  return (
    <GlobalContext.Provider value={{ ...defaultGlobalContext, ...options }}>
      <Provider>{children}</Provider>
      <Toaster
        toastOptions={{
          position: "top-center",
          duration: 3000,
        }}
      />
      <div id="portal-root" className="z-50"></div>
    </GlobalContext.Provider>
  );
};

export default UIProvider;
