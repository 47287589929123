import type { Path, Link as PrimativeLink } from "@remix-run/react";
import type { ComponentProps, ReactNode } from "react";

import cn from "../../util/cn.js";

interface Props extends ComponentProps<typeof PrimativeLink> {
  className?: string;
  to: Partial<Path> | string;
  children?: ReactNode;
}

const PostLink = ({ className, to, children, target }: Props) => {
  return (
    <form method="post" action={to as any as string} className="inline" target={target}>
      <button type="submit" className={cn("inline text-left", className)}>
        {children}
      </button>
    </form>
  );
};

export default PostLink;
