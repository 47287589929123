import type { TooltipContentProps } from "@radix-ui/react-tooltip";
import type { ReactNode } from "react";

import {
  Tooltip as ShadTooltip,
  TooltipContent as ShadTooltipContent,
  TooltipTrigger as ShadTooltipTrigger,
} from "../../shadcn/ShadTooltip.js";
import cn from "../../util/cn.js";

export interface TooltipProps extends Pick<TooltipContentProps, "side"> {
  children?: ReactNode;
  tooltip?: ReactNode;
  /** Shortens the delay to 100ms for "instant" tooltips. */
  instant?: boolean;
  tooltipClassName?: string;
  /** Use card when the tooltip has more content. Compact is default */
  variant?: "compact" | "card";
}

const Tooltip = ({
  children,
  tooltip,
  instant,
  side,
  tooltipClassName,
  variant = "compact",
}: TooltipProps) => (
  <ShadTooltip delayDuration={instant ? 100 : undefined}>
    <ShadTooltipTrigger asChild>{children}</ShadTooltipTrigger>
    <ShadTooltipContent
      className={cn(
        "shadow-lg bg-black dark border-none text-white",
        { "max-w-[250px] px-3 py-2": variant === "compact" },
        { "max-w-sm p-4": variant === "card" },
        tooltipClassName
      )}
      side={side}
    >
      {tooltip}
    </ShadTooltipContent>
  </ShadTooltip>
);

export default Tooltip;
