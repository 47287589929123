export type LineLimit = 1 | 2 | 3 | 4 | 5 | 6;

export const LINE_LIMIT_CLASSES: Record<LineLimit, string> = {
  1: "line-clamp-1",
  2: "line-clamp-2",
  3: "line-clamp-3",
  4: "line-clamp-4",
  5: "line-clamp-5",
  6: "line-clamp-6",
};
